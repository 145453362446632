import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['dom'];
  static values = {
    limit: Number
  }

  domTarget: any;
  limitValue: any;

  observer: any;

  connect() {
    const config: any = {
      attributes: false,
      childList: true,
      subtree: false
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
    // 
    this.observer = new MutationObserver((mutations, config) => {
      for(const mutation of mutations) {
        const lastChild: any = this.domTarget.lastElementChild;
        const shouldRemoveLastChildElement: boolean = (mutation.type === 'childList' && lastChild != null && this.domTarget.children.length > this.limitValue);
        
        if (shouldRemoveLastChildElement) setTimeout(() => { lastChild.remove(); }, 1);
      }
    });

    // https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver/observe
    //
    this.observer.observe(this.domTarget, config);
  }

  disconnect() {
    this.observer.disconnect();
  }
}